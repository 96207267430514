import { useState ,useEffect } from 'react'
import styled from "styled-components";
import { get } from '../../utils/request'
import { RouteComponentProps, useLocation, Link } from 'react-router-dom'

import { ReactComponent as ReturnArrow } from '../../assets/img/returnArrow_icon.svg'
import testImg2 from '../../assets/img/test_img2.png'
import opensea from '../../assets/img/opensea.png'
import { ModelsNftItem, ModelsNftStorage } from '../../interface'
import { useHistory } from "react-router-dom";
import { ReactComponent as ExternalLink } from '../../assets/img/externalLink.svg'

const SearchDetailPage = (props: RouteComponentProps<{ NFTId: string }>) => {
  const {
    match: {
      params: { NFTId }
    }
  } = props
  console.log("NFTId", NFTId)
  let history = useHistory();
  const [NFTData, setNFTData] = useState<ModelsNftItem>({})
  useEffect(() => {
    get(`https://staging-api.sao.network/v1/nft/${NFTId}`)
    .then(data => {
      console.log("data", data)
      if (data) {
        setNFTData(data.data)
      }
    })
  }, [])

  return (
    <SearchDetail>
      <DetailContent>
        <DetailLeft>
          <NFTTitle>
            <ReturnArrow onClick={() => {history.goBack()}} />
             {NFTData?.Name} {NFTData?.TokenId}
          </NFTTitle>
          {NFTData.Url ? <NFTImg src={NFTData.Url}  /> : <DefaultImgBackground />}
          <DecriptionTitle>
            Description
          </DecriptionTitle>
          <DescriptionContent>
            {NFTData?.Description}
          </DescriptionContent>
        </DetailLeft>
        <DetailRight>
          <Top>
            <DetailRow>
              {/* <DetailItem>
                <ItemTitle>
                  Platform
                </ItemTitle>
                <ItemContent isFirstrow={true}>
                  <img src={NFTData?.Platform?.LogoUrl} />  {NFTData?.Platform?.Name}
                </ItemContent>
              </DetailItem> */}
              <DetailItem>
                <ItemTitle>
                  Collection
                </ItemTitle>
                <ItemContent isFirstrow={true}>
                  {/* {NFTData?.Collection?.Name} */}
                  <a href={`https://etherscan.io/address/${NFTData?.Collection?.ContractAddress}`}>{NFTData?.Collection?.Name || "unknow"}</a>
                </ItemContent>
              </DetailItem>
            </DetailRow>
          </Top>
          <Middle>
            <DetailRow>
              <DetailItem>
                <ItemTitle>
                  Contract Address
                </ItemTitle>
                <ItemContent >
                  <a href={NFTData.ContractAddress} target="_blank" >{`${NFTData.ContractAddress?.slice(0, 5)} ... ${NFTData.ContractAddress?.slice(NFTData.ContractAddress.length - 4, NFTData.ContractAddress.length)}`}</a>
                </ItemContent>
              </DetailItem>
              <DetailItem>
                <ItemTitle>
                  Blockchain
                </ItemTitle>
                <ItemContent>
                  {NFTData.Network?.Name}
                </ItemContent>
              </DetailItem>
            </DetailRow>
            <DetailRow>
              <DetailItem>
                <ItemTitle>
                  Blockchain
                </ItemTitle>
                <ItemContent >
                  ERC 721
                </ItemContent>
              </DetailItem>
              <DetailItem>
                <ItemTitle>
                  Token ID
                </ItemTitle>
                <ItemContent>
                  {NFTData.TokenId}
                </ItemContent>
              </DetailItem>
            </DetailRow>
            <DetailRow>
              <DetailItem>
                <ItemTitle>
                  Minted
                </ItemTitle>
                <ItemContent>
                  <a href={NFTData.OwnerAddress} >{NFTData.OwnerAddress}</a>
                </ItemContent>
              </DetailItem>
              <DetailItem>
                <ItemTitle>
                  Owner
                </ItemTitle>
                <ItemContent>
                  <a href={NFTData.OwnerAddress} >{NFTData.OwnerAddress}</a>
                </ItemContent>
              </DetailItem>
            </DetailRow>
            <DetailRow>
              <DetailItem>
                <ItemTitle>
                  Avg. Price
                </ItemTitle>
                <ItemContent>
                  {NFTData.LastPrice} ETH
                </ItemContent>
              </DetailItem>
              <DetailItem>
                <ItemTitle>
                  Trading History
                </ItemTitle>
                <ItemContent>
                  <a>View</a>
                </ItemContent>
              </DetailItem>
            </DetailRow>
          </Middle>
          <Bottom>
            <StorageTitle>
              Storage Address
            </StorageTitle>
            {NFTData.NftStorages?.map((storage: ModelsNftStorage) => {
              if (storage.StorageType?.Id === 1 || storage.StorageType?.Id === 2) {
                return (
                  <StorageLink>
                    <PreFix>
                      {storage.StorageType?.Id === 1 ? "General Storage" : "IPFS Storage"}
                    </PreFix>
                    <input readOnly value={storage.StorageData} />
                  </StorageLink>
                )
              } else {
                return (
                  <SaoStorageLinkWraper>
                    <StorageLink isSaoLink={true}>
                      <PreFix isSaoLink={true}>
                        SAO Storage 
                      </PreFix>
                      <input readOnly value={storage.StorageData} />
                    </StorageLink>
                    <SaoStorageLinkBottom>
                      <SaoStorageLinkBottomItem>
                        <SaoStorageLinkBottomTitle>
                          Order ID
                        </SaoStorageLinkBottomTitle>
                        <SaoStorageLinkBottomContent>
                          <a href={`${storage?.Id}`} >{storage.Id}</a>
                        </SaoStorageLinkBottomContent>
                      </SaoStorageLinkBottomItem>
                      <SaoStorageLinkBottomItem>
                        <SaoStorageLinkBottomTitle>
                          Notary
                        </SaoStorageLinkBottomTitle>
                        <SaoStorageLinkBottomContent>
                          <a href={`${storage?.Id}`} >{storage.StorageData}</a>
                        </SaoStorageLinkBottomContent>
                      </SaoStorageLinkBottomItem>
                    </SaoStorageLinkBottom>
                  </SaoStorageLinkWraper>
                )
              }
            })}
          </Bottom>
        </DetailRight>
      </DetailContent>
    </SearchDetail>
  )
}
const SearchDetail = styled.div`
  padding-top: 70px;
  text-align: center;
`

const DetailContent = styled.div`
  padding-top: 40px;
  display: inline-grid;
  grid-template-columns: 500px 600px;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
`

const DetailLeft = styled.div`
  text-align: left;
`

const DetailRight = styled.div`
  border: 1px solid #BBBBBB;
  border-radius: 4px;
`

const Top = styled.div`
  padding: 20px 40px;
`

const Middle = styled.div`
  border-top: 1px solid #BBBBBB;
  border-bottom: 1px solid #BBBBBB;
  padding: 30px 40px;
`

const Bottom = styled.div`
  padding: 30px 40px;
`

const NFTTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 37px;
  font-weight: bold;
  svg {
    margin-right: 20px;
    cursor: pointer;
  }
`
const DefaultImgBackground = styled.div`
  width: 500px;
  height: 500px;
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #F4F4F4;
`

const NFTImg = styled.img`
  width: 500px;
  height: 500px;
  margin-top: 20px;
  margin-bottom: 30px;
`
const DecriptionTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`

const DescriptionContent = styled.div`
  margin-top: 10px;
`

const DetailRow = styled.div`
  display: flex;
  /* margin-bottom: 30px; */
`

const DetailItem = styled.div`
  text-align: left;
  margin-right: 72px;
`

const ItemTitle = styled.div`
  font-size: 14px;
  margin-bottom: 6px;
`

const ItemContent = styled.div<{ isFirstrow?: boolean }>`
  min-width: 169px;
  font-weight: bold;
  font-size: ${props => props.isFirstrow ? "24px" : "20px"};
`

const StorageTitle = styled.div`
  text-align: left;
  margin-bottom: 10px;
`

const StorageLink = styled.div<{ isSaoLink?: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${props => props.isSaoLink ? "#0FAE32" : "#DEDEDE"};
  border-radius: 80px;
  overflow: hidden;
  margin-bottom: 14px;
  input {
    padding-left: 13px;
    border: none;
    width: calc(100% - 150px);
    &:focus-visible {
      outline: none;
    }
  }
  .external {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translate(0, -50%);
    cursor: pointer;
  }
`

const PreFix = styled.div<{ isSaoLink?: boolean }>`
  font-weight: ${props => props.isSaoLink ? "bold" : "normal"};
  color: ${props => props.isSaoLink ? "#FFFFFF" : "#000000"};
  min-width: 150px;
  background: ${props => props.isSaoLink ? "#0FAE32" : "#F4F4F4"};
  padding: 10px 18px;
  border-right: 1px solid ${props => props.isSaoLink ? "#0FAE32" : "#DEDEDE"};
`
const SaoStorageLinkWraper = styled.div`
  padding-top: 42px;
  position: relative;
  background: #F9F9F9;
  border: 1px solid #DEDEDE;
  border-radius: 20px;
  ${StorageLink} {
    position: absolute;
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;
  }
`
const SaoStorageLinkBottom = styled.div`
  padding: 18px 27px;
  display: flex;
`

const SaoStorageLinkBottomItem = styled.div`
  &:first-child {
    padding-right: 70px;
  }
  &:nth-of-type(2) {
    padding-left: 70px;
  }
`

const SaoStorageLinkBottomTitle = styled.div`
  margin-bottom: 6px;
  text-align: left;
`

const SaoStorageLinkBottomContent = styled.div`
  font-size: 20px;

`

export default SearchDetailPage