// FloatDiv.jsx
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import './index.less'

const floatDomClassName = '.FloatDiv_component .FloatDiv_component_aniBox'
let _throttleFn
let doms = []
function FloatDiv({ children, delay = 0, down = 50 ,zindex =1}) {
    // eslint-disable-next-line no-unused-vars
    const [a, as] = useState([])
    const [isShow] = useState(delay == 3)
        
    useEffect(() => {
      const d = Array.prototype.slice.call(
        document.querySelectorAll(floatDomClassName)
      )
      doms = d
      init(delay)
    }, [])
  
    const init = (delay) => {
        
      if ('IntersectionObserver' in window) {
        let floatDomObserver = new IntersectionObserver((entries) => {
           
          entries.forEach((entry, index) => {
            if (entry.isIntersecting) {
              let floatDom = entry.target
              const timer = setTimeout(() => {
                floatDom.className = 'box1 FloatDiv_component_aniClass'
             
                clearTimeout(timer)
              }, 100)
              floatDomObserver.unobserve(floatDom)
              doms.splice(index, 1)
            }
          })
        })
        doms.forEach((floatDomItem) => {
          floatDomObserver.observe(floatDomItem)
        })
      } else {
        inViewShow()
        _throttleFn = throttle(inViewShow)
        document.addEventListener('scroll', _throttleFn.bind(this))
      }
    }
  
    const inViewShow = () => {
      let len = doms.length
      for (let i = 0; i < len; i++) {
        let targetFloatElement = doms[i]
        const { top, bottom } = targetFloatElement.getBoundingClientRect()
        if (
          top - down + 10 < document.documentElement.clientHeight &&
          bottom > 0
        ){
          const timer = setTimeout(() => {
            targetFloatElement.className = 'FloatDiv_component_aniClass'
            
            clearTimeout(timer)
          }, 100)
          
          doms.splice(i, 1)
          len--
          i--
          if (doms.length === 0) {
            document.removeEventListener('scroll', _throttleFn)
          }
        }
      }
    }
    const throttle = (fn, delay = 100, mustRun = 30) => {
      let t_start = null
      let timer = null
      let context = this
      return function () {
        let t_current = +new Date()
        let args = Array.prototype.slice.call(arguments)
        clearTimeout(timer)
        if (!t_start) {
          t_start = t_current
        }
        if (t_current - t_start > mustRun) {
          fn.apply(context, args)
          t_start = t_current
        } else {
          timer = setTimeout(() => {
            fn.apply(context, args)
          }, delay)
        }
      }
    }
    return (
      <div className="FloatDiv_component"  style={{
       'z-index': `${zindex}`
      }}>

      <div
          className="FloatDiv_component_aniBox"
          style={{
            transition: `all 0.5s ease ${delay}s`,
            transform: `translate(0, ${down}px)`,
        
          }}
        >
          {children}
        </div>
        
      </div>
    )
  }
  FloatDiv.propTypes = {
    children: PropTypes.element,
    delay: PropTypes.number,
    down: PropTypes.number
  }


export default FloatDiv
