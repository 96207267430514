import { useCallback, useEffect, useState } from 'react';
import { Tooltip as AnTooltip, Badge, Button } from 'antd';
import { QuestionCircleFilled, RightOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment';
import numeral from 'numeral';
import { useHistory } from "react-router";
import { useMinerDetail, usePowerAndEarningChangeData, submitWithdrawalRequest, useMinerMaintenance } from 'views/hooks';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import MiningSearch from 'components/MiningSearch';
import { cidStartAndEnd } from 'components/Cid'
import formatFIL from 'utils/formatFIL';
import formatBytes from 'utils/formatBytes';
import WithdrawModal from './WithdrawModal';
import {ReactComponent as Change} from '../../assets/img/change.svg'
import {ReactComponent as TableIcon} from '../../assets/img/table.svg'
import {ReactComponent as Product} from '../../assets/img/product.svg'
import {ReactComponent as NoData} from 'assets/img/nodata.svg'
import {ReactComponent as WarningIcon} from 'assets/img/warning.svg'
import {ReactComponent as MaintenanceIcon} from 'assets/img/maintenance.svg'
import Oops from 'assets/img/Oops.png'

import './index.less'

const Miner = (props: RouteComponentProps<{ address: string }>) => {
    const {
      match: {
        params: { address }
      }
    } = props
    const history = useHistory();
    const { account, library } = useActiveWeb3React()
    const [refresh, setRefresh] = useState<number>(1);
    const {minerInfo, noMiner} = useMinerDetail(address, '', refresh);
    const powerAndEarningChangeData = usePowerAndEarningChangeData(minerInfo?.accountId);
    const [withdrawLoading, setWithdrawLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const maintenance = useMinerMaintenance()

    const wtihDraw = useCallback(async() => {
        setWithdrawLoading(true)
        const res: any = await submitWithdrawalRequest(library, account);
        if(res && res.code === '200'){
            setRefresh(refresh + 1)
            setWithdrawLoading(false);
        }else{
            setWithdrawLoading(false)
            setRefresh(refresh + 1)
        }
        setIsModalOpen(false);
    },[library, account, refresh])

    return (
        <div className="miner-detail">
            <WithdrawModal visible={isModalOpen} amount={minerInfo?.withdrawal?.amount} address={address} handleCancel={() => {setIsModalOpen(false)}} handleOk={() => {wtihDraw()}}/>
            <MiningSearch address={address}/>
            <div className="container">
                {noMiner && 
                    <div className="no-miner content">
                        <img src={Oops} alt="" />
                        <p>No mining information found for</p>
                        <p>"{address}"</p>
                        <p><a href="mailto:contact@sao.network">if you need help, Click here.</a></p>   
                    </div>
                }
                {!noMiner && 
                    <>
                        <div className="descriptions">
                            <div className="content">
                                <div>
                                    <div>Address</div>
                                    <div>{minerInfo.address ? `${cidStartAndEnd(minerInfo.address).start}...${cidStartAndEnd(minerInfo.address).end}` : ''}</div>
                                </div>    
                                <div>
                                    <div>Total Deposit</div>
                                    <div>{formatFIL(minerInfo.totalDeposit)}</div>
                                </div>
                                <div>
                                    <div>
                                        Total Assets
                                    </div>
                                    <div>{formatFIL(minerInfo.totalAsset)}</div>
                                </div>
                                <div>
                                    <div>
                                        Total Paid
                                    </div>
                                    <div>{formatFIL(minerInfo.paidEarning)}</div>
                                </div>
                                <div>
                                    <Button type='primary' icon={<TableIcon fill="#fff"/>} onClick={() => {history.push(`/miner-account-history/${address}`)}}>Account Details</Button>
                                </div>
                            </div>
                        </div>
                        {maintenance.enabled && 
                            <div className='maintenance'>
                                <MaintenanceIcon/>
                                <p>The site is under temporary maintenance and will be restored within 1 hour.</p>
                            </div>
                        }
                        {!maintenance.enabled && 
                            <>
                                {powerAndEarningChangeData.length === 0 && 
                                    <div className='warning'>
                                        <WarningIcon/>
                                        <p>The mining data shall be revealed as soon as the product's Public Offering ends when your total shares of mining power are finalized at that point.</p>
                                    </div>
                                }
                                <div className='miner-data'>
                                    <div className="change">
                                        <div className="power">
                                            <div className="title">
                                                <Change/> Power Change
                                            </div>  
                                            <div className="content">
                                                {powerAndEarningChangeData.length > 0 &&
                                                    <div className="chart">
                                                        <ResponsiveContainer width="99%" height="100%" debounce={1}>
                                                            <LineChart
                                                                data={powerAndEarningChangeData}
                                                                margin={{
                                                                    top: 15,
                                                                    right: 20,
                                                                    left: 5,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid vertical={false}/>
                                                                <XAxis dataKey="time" tickFormatter={(time) => moment(time).format('MM-DD')}/>
                                                                <YAxis width={80} tickFormatter={(v) => formatBytes(v)}/>
                                                                <Tooltip labelFormatter={(time) => moment(time).format('YYYY-MM-DD')} formatter={(value: any, name: any) => [formatBytes(value), name]}/>
                                                                <Legend verticalAlign="bottom" wrapperStyle={{ fontSize: '14px', bottom : '10px' }}/>
                                                                <Line type="monotone" dataKey="power" name="Power" stroke="#5ab1ef" dot={false}/>
                                                            </LineChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                }
                                                {powerAndEarningChangeData.length === 0 && 
                                                    <div className="nodata">
                                                        <NoData/>
                                                        <div>No data</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="earning">
                                            <div className="title">
                                                <Change/> Earning Change
                                            </div>  
                                            <div className="content">
                                                {powerAndEarningChangeData.length > 0 &&
                                                    <div className="chart">
                                                        <ResponsiveContainer width="99%" height="100%" debounce={1}>
                                                            <LineChart
                                                                data={powerAndEarningChangeData}
                                                                margin={{
                                                                    top: 15,
                                                                    right: 20,
                                                                    left: 5,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid vertical={false}/>
                                                                <XAxis dataKey="time" tickFormatter={(time) => moment(time).format('MM-DD')}/>
                                                                <YAxis width={50} tickFormatter={(v) => `${numeral(formatFIL(v)).format('0,0')} FIL`}/>
                                                                <Tooltip labelFormatter={(time) => moment(time).format('YYYY-MM-DD')} formatter={(value: any, name: any) => [`${formatFIL(value)}`, name]}/>
                                                                <Legend verticalAlign="bottom" wrapperStyle={{ fontSize: '14px', bottom : '10px' }}/>
                                                                <Line type="monotone" dataKey="earning" name="Earning" stroke="#19d4ae" dot={false}/>
                                                            </LineChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                }
                                                {powerAndEarningChangeData.length === 0 && 
                                                    <div className="nodata">
                                                        <NoData/>
                                                        <div>No data</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data-content">
                                        <div className="content">
                                            <div className="first">
                                                <div>
                                                    <div>
                                                        <AnTooltip placement="right" title="Pledge">
                                                            Adj. Power&nbsp;<QuestionCircleFilled/>
                                                        </AnTooltip>
                                                    </div>
                                                    <div>
                                                        {formatBytes(minerInfo.powerAdj)} ({formatFIL(minerInfo.staked)})
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <AnTooltip placement="right" title="Earnings to be paid">
                                                            Available Rewards&nbsp;<QuestionCircleFilled/>
                                                        </AnTooltip>
                                                    </div>
                                                    <div>{formatFIL(minerInfo.availableEarning)}</div>
                                                </div>
                                            </div>
                                            <div className="second">
                                                <div>
                                                    <div>Pending Assets</div>
                                                    <div>{formatFIL(minerInfo.unStaked)}</div>
                                                </div> 
                                                <div>
                                                    <div>Gas Fee</div>
                                                    <div>{formatFIL(minerInfo.gasFee)}</div>
                                                </div>
                                                <div>
                                                    <div>Locked Rewards</div>
                                                    <div>{formatFIL(minerInfo.locked)}</div>
                                                </div>
                                                <div>
                                                    <div>Total Rewards</div>
                                                    <div>{formatFIL(minerInfo.totalEarning)}</div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="withdraw">
                                            <div className="content">
                                                <div>
                                                    <div>Withdrawable Amount</div>
                                                    <div>{formatFIL(minerInfo?.withdrawal?.amount)}</div>
                                                </div>
                                                <div>
                                                    <Button type="primary" onClick={() => {setIsModalOpen(true)}} disabled={withdrawLoading || minerInfo?.withdrawal?.status === 0 || minerInfo?.withdrawal?.status === 2}>{withdrawLoading || minerInfo?.withdrawal?.status === 0 ? 'Processing...': 'Withdraw'}</Button>
                                                    <p>Min: 0.1 FIL</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="title">
                                    <Product/> Product list
                                </div>  
                                {minerInfo.joinedProductList && minerInfo.joinedProductList.map((miner: any) => 
                                    <div key={miner.productId} className="content miner-info" onClick={() => {history.push(`/miner/${address}/${miner.productId}`)}}>
                                        <div className="mining-data main">
                                            <div>
                                                <div>Product</div>
                                                <div>{miner.productName}</div>
                                            </div>  
                                            <div>
                                                <div>Mining Days</div>
                                                <div>
                                                    {miner.miningDays}
                                                </div>
                                            </div>
                                            <div>
                                                <div>Est. Maturity</div>
                                                <div>{miner.closeTime && moment(miner.closeTime).format('YYYY-MM-DD HH:mm:ss')}</div>
                                            </div> 
                                            <div>
                                                <div>
                                                    <AnTooltip placement="right" title="*Intelligent Analysis Based On Economic Model">
                                                    Est. APY&nbsp;<QuestionCircleFilled/>
                                                    </AnTooltip>
                                                </div>
                                                <div>{(miner.apy * 100).toFixed(2)}%</div>
                                            </div>
                                            <div>
                                                <div> 
                                                    Assets
                                                </div>
                                                
                                                <div>{formatFIL(miner.asset)}</div>
                                            </div>
                                            <div>
                                                <RightOutlined style={{fontSize: '20px'}}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {minerInfo.pendingProductList && minerInfo.pendingProductList.map((miner: any) => 
                                    <div key={miner.productId} className="content miner-info" onClick={() => {history.push(`/miner/${address}/${miner.productId}`)}}>
                                        <div className="mining-data main">
                                            <div>
                                                <div>Product</div>
                                                <div>{miner.productName}</div>
                                            </div>  
                                            <div></div> 
                                            <div></div>
                                            <div></div>
                                            <div className="pending">
                                                <Badge color="#FFDC60" text="Pending" />
                                            </div>
                                            <div>
                                                <RightOutlined style={{fontSize: '20px'}}/>
                                            </div>
                                        </div>
                                    </div>
                                )}   
                            </>
                        }
                    </>
                } 
            </div>
        </div>
    )
}

export default Miner
