import { useCallback, useEffect, useState } from 'react';
import Web3 from "web3";
import { get, request } from 'utils/request';
import { API_URL, MINING_API_URL } from 'constants/index';
import { getSign, clearLocalStorage } from 'utils/txSign';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import throttle from 'lodash.throttle';

export function useMiningPoolList(): any{
    const [list, setList] = useState<any>(new Array(1).fill({}));
    const fetchData = () => {
        get(`${API_URL}/sao/api/product/getProductList`).then((res: any) => {
            if(res && res.data){
                setList(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[])
    return list;
}

export function useBlockList(productId: string, pagination: any): any{
    const [data, setData] = useState<any>({});
    const fetchData = () => {
        let params: any = {
            productId,
            page: pagination.current,
            size: pagination.pageSize,
        };
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        get(`${API_URL}/sao/api/product/getBlockList?${query}`).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[productId, pagination])
    return data;
}


export function useDepositList(productId: string, pagination: any): any {
    const [data, setData] = useState<any>({});
    const fetchData = () => {
        let params: any = {
            productId,
            page: pagination.current,
            size: pagination.pageSize,
        };
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        get(`${API_URL}/sao/api/product/getDepositList?${query}`).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[productId, pagination])
    return data;
}

export function useMiningPoolDetail(id: string): any{
    const [data, setData] = useState<any>({});
    const fetchData = () => {
        get(`${API_URL}/sao/api/product/getProductDetail?productId=${id}`).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[])
    return data;
}

export function useMiningPoolChangeData(id: string): any{
    const [list, setList] = useState<any>([]);
    const fetchData = () => {
        get(`${API_URL}/sao/api/product/getChangeData?productId=${id}`).then((res: any) => {
            if(res && res.data.length > 0){
                const arr = res.data.map((v: any) => {
                    return {
                        ...v,
                        availableBalance: Number(v.availableBalance),
                        earning: Number(v.earning),
                        initialPledge: Number(v.initialPledge),
                        lockedRewards: Number(v.lockedRewards),
                        power: Number(v.power),
                        totalBalance: Number(v.totalBalance)
                    }
                })
                setList(arr)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[])
    return list;
}

export function useMinerDetail(address: string, productId?: string, refresh = 1): any {
    const [minerInfo, setMinerInfo] = useState<any>({});
    const [noMiner, setNoMiner] = useState<boolean>(false);
    const [joinedProductList, setJoinedProductList] = useState<any>([])
    const [pendingProductList, setPendingProductList] = useState<any>([])
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/getUserDetail?address=${address}${productId? `&productId=${productId}`:''}`, sign).then((res: any) => {
            if(res && res.data){
                setMinerInfo(res.data)
                if(!productId){
                    setJoinedProductList(res.data.joinedProductList)
                    setPendingProductList(res.data.pendingProductList)
                }
            }else {
                setNoMiner(true)
            }
        })
    },[address, productId])
    useEffect(() => {
        if(address){
            fetchData()
        }
    },[address, productId, refresh])
    return {minerInfo, noMiner, joinedProductList, pendingProductList};
}


export function useMinerMaintenance(): any {
    const [data, setData] = useState<any>({});
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/maintenance`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    },[account])
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account])
    return data;
}

export function usePowerAndEarningChangeData(userAccountId: string, productId?: string): any {
    const [data, setData] = useState<any>([]);
    const { account, library } = useActiveWeb3React()
    const fetchData = async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/getPowerAndEarningChangeData?userAccountId=${userAccountId}${productId? `&productId=${productId}`:''}`, sign).then((res: any) => {
            if(res && res.data.length > 0){
                const arr = res.data.map((v: any) => {
                    return {
                        ...v,
                        power: Number(v.power),
                        earning: Number(v.earning),
                    }
                })
                setData(arr)
            }
        })
    }
    useEffect(() => {
        if(userAccountId){
            fetchData()
        }
    },[userAccountId, productId])
    return data;
}

export function useTransactionList(userAccountId: string, pagination: any, type: string, productId: string | undefined): any {
    const [data, setData] = useState<any>({});
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async() => {
        const sign = await getSign(library, account, false)
        let params: any = {
            userAccountId,
            productId,
            type,
            page: pagination.current,
            size: pagination.pageSize,
        };
        if(productId === 'all' || !productId) {delete params.productId}
        if(!type) {delete params.type}
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        get(`${API_URL}/sao/api/userDetail/getTransactionList?${query}`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    },[userAccountId, pagination, type, productId])
    useEffect(() => {
        if(userAccountId){
            fetchData()
        }
    },[userAccountId, pagination, type, productId])
    return data;
}

export function usePaymentList(address: string, productId?: string): any {
    const [list, setList] = useState<any>([]);
    const { account, library } = useActiveWeb3React()
    const fetchData = async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/getPaymentList?address=${address}${productId? `&productId=${productId}`:''}`, sign).then((res: any) => {
            if(res && res.data){
                setList(res.data)
            }
        })
    }
    useEffect(() => {
        fetchData()
    },[])
    return list;
}

export function useUserAddressList(address: string): any {
    const [data, setData] = useState<any>([]);
    const { account, library } = useActiveWeb3React()
    const fetchData = async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/listAddresses?address=${address}`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }
    useEffect(() => {
        if(address){
            fetchData()
        }
    },[address])
    return data;
}

export function useNFTWithdrawalList(pagination: any): any {
    const [data, setData] = useState<any>([]);
    const { account, library } = useActiveWeb3React()
    const fetchData = async() => {
        let params: any = {
            page: pagination.current,
            size: pagination.pageSize,
        }
        const query = Object.keys(params)
                    .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/getNftWithdrawalRecords?${query}`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
        })
    }
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account])
    return data;
}

export const bindEtherAddressToFilAddress  = async(filAddress: string, address: any, library: any)  => {
    const header = await getSign(library, address, false)

    try {
        // const params: any = { filAddress};
        // const query = Object.keys(params)
        //             .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        //             .join('&');
        const url = `${API_URL}/sao/api/userDetail/bindEtherAddressToFilAddress` 
        const res = await request(url, {
            body: `filAddress=${filAddress}`,
            headers: {
                ...header,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
}

export function useFilAddress(refresh = 1): any{
    const [data, setData] = useState<string>('');
    const { account, library } = useActiveWeb3React()
    const fetchData = useCallback(async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/getFilAddress`, sign).then((res: any) => {
            if(res && res.code === '200'){
                setData(res.data)
            }
        })
    }, [library, account])

    useEffect(() => {
        if(account && library){
            fetchData()
        }
    },[account, library, refresh])
    return data;
}

const withdrawSign = (provider: any, msg: string, fromAddress: string, pwd: string) => {
    msg = 'You are initiating a withdrawal request.\n\n' +
    'Click "Sign" to continue. \nThis signature costs no gas fee.\n\n' +
    'Wallet address:\n' +
    fromAddress + '\n\n' +
    'Nonce:\n' + msg;

    const web3 = new Web3(provider);
    return new Promise((resolve, reject) => {
        web3.eth.personal.sign(msg, fromAddress, pwd).then(res => {
            resolve({address: fromAddress, signature: res, signatureMessage: encodeURIComponent(msg)})
        }).catch(err => {
            resolve(null)
        })
    })
}

export const submitWithdrawalRequest  = async(library: any, account: any)  => {
    let signatureMessage: any = Date.now();
    const header: any = await withdrawSign(library.provider, signatureMessage.toString(), account, '')
    if(!header){
        return null
    }
    try {
        const url = `${API_URL}/sao/api/userDetail/submitWithdrawalRequest` 
        const res = await request(url, {
            headers: {
                ...header,
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
      return null
    }
}
export const submitNFTWithdrawalRequest  = async(filAddress: string,library: any, account: any)  => {
    let signatureMessage: any = Date.now();
    const header: any = await withdrawSign(library.provider, signatureMessage.toString(), account, '')
    if(!header){
        return null
    }
    try {
        const url = `${API_URL}/sao/api/userDetail/submitWithdrawalRequest?productType=nft&filAddress=${filAddress}` 
        const res = await request(url, {
            headers: {
                ...header,
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
      return null
    }
}

export const signatureError = (res: any, deactivate: any, connector: any) => {
    if(res && res?.code && res?.code.includes('signature') ){
        // deactivate();
        // clearLocalStorage()
        // if(connector === walletconnect){
        // //@ts-ignore
        // connector.close();
        // }
    }
}


export function useFileList(pagination: any, refresh: number, filename?: string, fromApp?: string): any{
    const [data, setData] = useState<any>({total: 0});
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = useCallback(async() => {
        let params: any = {
            page: pagination.current,
            size: pagination.pageSize,
            filename: filename,
            fromApp: fromApp,
        };
        if(!filename) delete params.filename
        if(!fromApp || fromApp === 'all') delete params.fromApp
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        const sign = await getSign(library, account, false);
        get(`${API_URL}/sao-data-store/api/file/list?${query}`, sign).then((res: any) => {
            if(res && res.data){
                if (res.data.page === 1) {
                    setData(res.data)
                } else {
                    setData((oldData: any) => {return {...oldData, list: [...oldData.list, ...res.data.list]}})
                }
            }
            signatureError(res, deactivate, connector);
        })
    },[account, library, pagination, refresh, filename, fromApp])
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account, pagination, refresh, filename, fromApp])
    return data;
}

export function useFileDetail(fileId: string): any{
    const [data, setData] = useState<any>({});
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = async() => {
        const sign = await getSign(library, account, false);
        get(`${API_URL}/sao-data-store/api/file/getInfo?fileId=${fileId}`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }
    useEffect(() => {
        if(account && fileId){
            fetchData()
        }
    },[account, fileId])
    return data;
}


export function useAppList(pagination: any, refresh: number): any{
    const [data, setData] = useState<any>({});
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = useCallback(async() => {
        let params: any = {
            page: pagination.current,
            size: pagination.pageSize,
        };
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        const sign = await getSign(library, account, false);
        get(`${API_URL}/sao-data-store/api/app/list?${query}`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }, [account, library, pagination, refresh])
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account, pagination, refresh])
    return data;
}

export function useAppNameList(refresh: number): any{
    const [data, setData] = useState<any>([]);
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = async() => {
        const sign = await getSign(library, account, false);
        get(`${API_URL}/sao-data-store/api/app/listAppNames`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account, refresh])
    return data;
}

export function useAppOverview(refresh: number): any{
    const [data, setData] = useState<any>({});
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = async() => {
        const sign = await getSign(library, account, false);
        get(`${API_URL}/sao-data-store/api/app/getUserOverview`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account, refresh])
    return data;
}

export function useAppDetail(appId: string): any{
    const [data, setData] = useState<any>({});
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = async() => {
        const sign = await getSign(library, account, false);
        get(`${API_URL}/sao-data-store/api/app/get?appId=${appId}`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }
    useEffect(() => {
        if(account && appId){
            fetchData()
        }
    },[account, appId])
    return data;
}


export const createApp  = async(data: any, address: any, library: any)  => {
    const header = await getSign(library, address, false)

    try {
        const url = `${API_URL}/sao-data-store/api/app/create` 
        const res = await request(url, {
            body: JSON.stringify(data),
            headers: {
                ...header,
                'Content-Type': 'application/json',
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
}

export const updateApp  = async(data: any, address: any, library: any)  => {
    const header = await getSign(library, address, false)

    try {
        const url = `${API_URL}/sao-data-store/api/app/update` 
        const res = await request(url, {
            body: JSON.stringify(data),
            headers: {
                ...header,
                'Content-Type': 'application/json',
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
}

export const deleteApp  = async(appId: string, address: any, library: any)  => {
    const header = await getSign(library, address, false)

    try {
        const url = `${API_URL}/sao-data-store/api/app/delete` 
        const res = await request(url, {
            body: appId,
            headers: {
                ...header,
                'Content-Type': 'application/json',
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
}

export const deleteFile  = async(ids: any, address: any, library: any)  => {
    const header = await getSign(library, address, false)
    try {
        const url = `${API_URL}/sao-data-store/api/file/delete` 
        const res = await request(url, {
            body: JSON.stringify(ids),
            headers: {
                ...header,
                'Content-Type': 'application/json',
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
    }
}


export const getFile  = async(id: string, appId: string, apiKey: string, address: any, library: any)  => {
    var authorizationBasic = window.btoa(appId + ':' + apiKey);
    try {
        const url = `${API_URL}/saods/api/v1/file/by-id/${id}` 
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + authorizationBasic,
            },
            method: 'GET'
        }).then((res: any) => {
            if (!res.ok) {
              // 服务器异常返回
              throw Error('');
            }
            return res.blob();
          }).then((data: any) => {
            return data
          }).catch((error: any) => {
            // 公共错误处理
            console.error('内部错误');
        });
    } catch (e) {
      console.log('get userinfo error', e)
    }
}

export function useNFTDetail(tokenId: string): any{
    const [data, setData] = useState<any>({});
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = async() => {
        const sign = await getSign(library, account, false);
        get(`${MINING_API_URL}/miningnft/${tokenId}`, sign).then((res: any) => {
            if(res && res.image){
                setData(res)
            }
            if(res && res.success === false){
                setTimeout(() => {
                    fetchData()
                }, 2000)
            }
            signatureError(res, deactivate, connector);
        })
    }
    useEffect(() => {
        if(account && tokenId){
            fetchData()
        }
    },[account, tokenId])
    return data;
}

export function useNFTSummary(): any{
    const [data, setData] = useState<any>({});
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = async() => {
        const sign = await getSign(library, account, false);
        get(`${MINING_API_URL}/summary`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account])
    return data;
}

export function useNFTList(staked: boolean, refresh: number): any{
    const [data, setData] = useState<any>([]);
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = useCallback(async() => {
        let params: any = {
            staked
        };
        if(!staked){ delete params.staked }
        let query = Object.keys(params)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
        const sign = await getSign(library, account, false);
        get(`${MINING_API_URL}/owner/${account}/nfts?${query}`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }, [account, library, staked, refresh])
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account, staked, refresh])
    return data;
}

export const claimNFTReward  = async(id: string,library: any, account: any)  => {
    const sign = await getSign(library, account, false);
    try {
        const url = `${MINING_API_URL}/reward/${id}` 
        const res = await request(url, {
            headers: {
                ...sign,
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
      return null
    }
}
export const claimAllNFTReward  = async(library: any, account: any)  => {
    const sign = await getSign(library, account, false);
    try {
        const url = `${MINING_API_URL}/claimAll` 
        const res = await request(url, {
            headers: {
                ...sign,
            },
            method: 'POST'
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
      return null
    }
}

export function useNFTWithdrawAddress(): any{
    const [data, setData] = useState<any>({});
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = async() => {
        const sign = await getSign(library, account, false);
        get(`${MINING_API_URL}/withdraw/address`, sign).then((res: any) => {
            if(res && res.data){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account])
    return data;
}

export function useNFTAccelerate(refresh?: number): any{
    const [data, setData] = useState<any>();
    const { account, library, deactivate, connector } = useActiveWeb3React();
    const fetchData = async() => {
        const sign = await getSign(library, account, false);
        get(`${MINING_API_URL}/accelerate`, sign).then((res: any) => {
            if(res && res.data >= 0){
                setData(res.data)
            }
            signatureError(res, deactivate, connector);
        })
    }
    useEffect(() => {
        if(account){
            fetchData()
        }
    },[account, refresh])
    return data;
}

export const forgeNFT = async(id: string, amount: number,library: any, account: any)  => {
    const sign = await getSign(library, account, false);
    try {
        const url = `${MINING_API_URL}/nft/${id}/forge` 
        const res = await request(url, {
            body: `amount=${amount}`,
            headers: {
                ...sign,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
        });
        return res
    } catch (e) {
      console.log('get userinfo error', e)
      return null
    }
}