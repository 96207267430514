import { useState } from 'react';
import { message, Modal, Button } from 'antd';
import formatFIL from 'utils/formatFIL';
import './index.less'

export default function WithdrawModal({visible, handleOk, handleCancel, address, amount}: any) {
    const [showAddress, setShowAddress] = useState<boolean>(false)
    return (
        <Modal className="withdrawModal" title={`Withdraw FIL`} footer={null} visible={visible} onOk={handleOk} onCancel={() => {handleCancel(); setShowAddress(false)}}>
           <div className="content">
               <div className="attention">
                    Attention
                    <ul>
                        <li>
                        Withdrawal gas fee: 0.01 FIL
                        </li>
                        <li>
                        Minimum withdrawal amount: 0.1 FIL
                        </li>
                        <li>
                        The payment will go through within 2 business days after you initiate a withdrawal request
                        </li>
                        <li>
                        If you need help, please email: contact@sao.network
                        </li>
                    </ul>
               </div>
               <div className="address"></div>
                <p>FIL Withdrawal Address</p>
                <div className="address">{address}</div>
                <p>Available Withdrawable Amount</p>
                <div className="amount">{formatFIL(amount)}</div>
                <div className="btns">
                    <Button type="primary" onClick={handleOk}>Withdraw</Button>
                </div>
           </div>
        </Modal>
    )
}