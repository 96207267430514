import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import { getContract } from '../utils'
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { MiningNFT_ABI } from '../constants/abis/SaoMiningNFT'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from 'constants/multicall'
// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useMiningNFTContract(tokenAddress: string): Contract | null {
  return useContract(tokenAddress, MiningNFT_ABI, true)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}
