import { useState } from 'react';
import { message, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {  CaretDownOutlined } from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment';
import { useHistory } from "react-router";
import { useMinerDetail, useTransactionList } from 'views/hooks';
import MiningSearch from 'components/MiningSearch';
import formatFIL from 'utils/formatFIL';
import Cid from 'components/Cid';

import {ReactComponent as Back} from '../../assets/img/back.svg'
import {ReactComponent as AccountDetail} from '../../assets/img/account-detail.svg'
import Oops from 'assets/img/Oops.png'

import './index.less'

const { Option } = Select;

const MinerAccountHistory = (props: RouteComponentProps<{ address: string, productId: string | undefined }>) => {
    const {
      match: {
        params: { address, productId }
      }
    } = props
    const history = useHistory();
    const [pagination, setPagination] = useState<any>({current: 1, pageSize: 10})
    const [type, setType] = useState<any>('')
    const [productIdValue, setProductIdValue] = useState<any>('')
    const {minerInfo, noMiner, joinedProductList, pendingProductList} = useMinerDetail(address);
    const transactionData = useTransactionList(minerInfo.accountId, pagination, type, productIdValue ? productIdValue : productId)

    const handleTableChange = (_pagination: any) => {
        setPagination(_pagination)
    }

    const transactionColumns:ColumnsType<any> = [
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            fixed: 'left',
            width: 110,
            render: (time: any) => moment(time).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 140,
            render: (v: any) => `${formatFIL(v)}`
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            width: 140,
            render: (v: any) => `${formatFIL(v)}`
        },
        {
            title: 'Product',
            dataIndex: 'productName',
            key: 'productName',
            width: 110,
        },
        {
            title: 'Note',
            dataIndex: 'type',
            key: 'type',
            width: 140,
            render:(v: number, record: any) => getNoteType(v, record.messageId)
        },
    ]


    const getNoteType = (type: number, messageId: string) => {
        switch (type){
            case 1: 
            case 6: 
                return <>Deposit (<Cid type="message" value={messageId}/>)</>;
            case 2: 
                return 'Gas Fee';
            case 3:
                return 'Mining Rewards - immediate release';
            case 4: 
            case 7:
                return 'Mining Collateral';
            case 5: 
                return 'Mining Rewards - linear vested';
            case 9: 
                return <>Withdrawal (<Cid type="message" value={messageId}/>)</>;
            case 10: 
                return 'Early Bird Bonus'
        }
    }

    const handleProductChange = (value: string) => {
        setPagination({current: 1, pageSize: 10})
        setProductIdValue(value)
    }
    const handleTypeChange = (value: string) => {
        setPagination({current: 1, pageSize: 10})
        setType(value)
    }

    return (
        <div className="miner-detail">
            <MiningSearch address={address}/>
            <div className="container">
                {noMiner && 
                    <div className="no-miner content">
                        <img src={Oops} alt="" />
                        <p>No mining information found for</p>
                        <p>"{address}"</p>
                        <p><a href="mailto:contact@sao.network">if you need help, Click here.</a></p>   
                    </div>
                }
                {!noMiner && 
                    <>
                        <div className="back">
                            <div onClick={() => {history.push(`/miner/${address}`)}}><Back/>Back</div>
                            <div></div>
                        </div>   
                        <div className="account-detail-head">
                            <div className="title">
                                <div><AccountDetail/>Account Details</div>
                                <p><Cid type="address" value={address}/></p>
                            </div>
                            <div>
                                <Select defaultValue={productId || "all"} suffixIcon={<CaretDownOutlined/>} onChange={handleProductChange}>
                                    <Option key="all" value="all">
                                        All Products
                                    </Option>
                                    {joinedProductList.map((miner: any) =>   
                                        <Option key={miner.productId} value={miner.productId}>
                                            {miner.productName}
                                        </Option>
                                    )}
                                    {pendingProductList.map((miner: any) =>   
                                        <Option key={miner.productId} value={miner.productId}>
                                            {miner.productName}
                                        </Option>
                                    )}
                                </Select>
                                <Select defaultValue="" suffixIcon={<CaretDownOutlined/>} onChange={handleTypeChange}>
                                    <Option key="" value="">
                                        All Types
                                    </Option>
                                    <Option key='9' value="9">
                                        Withdrawal
                                    </Option>
                                    <Option key='3' value="3">
                                        Mining Rewards - immediate release
                                    </Option>
                                    <Option key='5' value="5">
                                        Mining Rewards - linear vested
                                    </Option>
                                    <Option key='2' value="2">
                                        Gas Fee
                                    </Option>
                                    <Option key='4' value="4">
                                        Mining Collateral
                                    </Option>
                                    <Option key='1' value="1">
                                        Deposit
                                    </Option>
                                    <Option key='10' value="10">
                                        Early Bird Bonus
                                    </Option>
                                </Select>
                            </div>
                        </div>  
                        <Table columns={transactionColumns} dataSource={transactionData.list} pagination={{...pagination, total: transactionData.total}} scroll={{ y: 800 }} onChange={handleTableChange} rowKey={'id'}/>
                    </>
                }
            </div>
        </div>
    )
}

export default MinerAccountHistory
