import { Button, Progress, Table, Badge, Radio, Tooltip as AnTooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useEffect, useState, useCallback } from 'react';
import { useHistory } from "react-router";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { RouteComponentProps } from 'react-router-dom'
import Countdown from 'react-countdown';
import moment from 'moment';
import numeral from 'numeral';
import { useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from 'state/application/hooks'
import DiscordWeb3Status from 'components/DiscordWeb3Status'
import { useBlockList, useDepositList, useMiningPoolDetail, useMiningPoolChangeData } from 'views/hooks';
import { useFilAddress } from 'state/account/hooks';
import { useMinerDetail } from 'views/hooks';
import { get, request } from 'utils/request';
import { getSign, clearLocalStorage } from 'utils/txSign';
import formatBytes from 'utils/formatBytes';
import formatFIL from 'utils/formatFIL';
import { API_URL } from 'constants/index';
import Cid from 'components/Cid';
import {ReactComponent as JoinIcon} from 'assets/img/Join.svg'
import DiscordLogo from 'assets/img/discord_logo.svg'
import FIL from 'assets/img/fil.png'

import './index.less'


const Discord = (props: RouteComponentProps<{ discordId: string }>) => {
    const {
      match: {
        params: { discordId }
      }
    } = props;
    console.log('discordId', discordId)
    const history = useHistory();
    const toggleWalletModal = useWalletModalToggle()
    const { account, library } = useWeb3React()
    const address = useFilAddress();
    const [role, setRole] = useState('')
    const fetchData = useCallback(async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/getUserDetail?address=${address}$`, sign).then((res: any) => {
            if(res && res.data){
                if (res.data.joinedProductList.length > 0) {
                    setRole(res.data.joinedProductList[0].productName)
                }
            }
        })
    }, [address]);

    useEffect(() => {
        if (address) {
            fetchData()
        }
    
    }, [address]);

    const verifyRole = useCallback(async() => {
        const sign = await getSign(library, account, false)
        get(`${API_URL}/sao/api/userDetail/verifyUser?discordUserId=${discordId}`, sign).then((res: any) => {
            console.log(res)
        })
    }, [role])

    useEffect(() => {
        if (role) {
            verifyRole()
        }
    } , [role])

    return (
        <div className="discord">
                <img className="logo" src={DiscordLogo} />
                <DiscordWeb3Status role={role}/>
        </div>
    )
}

export default Discord
