import { configureStore } from '@reduxjs/toolkit'

import application from './application/reducer'
import account from './account/reducer'
import multicall from './multicall/reducer'

const store = configureStore({
  reducer: {
    application,
    account,
    multicall,
  }
})


export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
