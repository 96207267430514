import { useState, useEffect } from 'react';
import { message, Collapse, Button } from 'antd'
import FloatDiv from '../../components/FloatDiv'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import group1 from 'assets/img/group1.png'
import group2 from 'assets/img/group2.png'
import group3 from 'assets/img/group3.png'
import group4 from 'assets/img/group4.png'
import groupline1 from 'assets/img/groupline1.png'

import groupline2 from 'assets/img/groupline2.png'


import groupBg from 'assets/img/groupBg.png'
import threetop from 'assets/img/threetop.png'
import threebtm from 'assets/img/threebtm.png'
import threeicon1 from 'assets/img/threeicon1.png'
import threeicon2 from 'assets/img/threeicon2.png'
import threeicon3 from 'assets/img/threeicon3.png'
import roadmapbg from 'assets/img/roadmapbg.png'
import updown from 'assets/img/updown.png'
import downup from 'assets/img/downup.png'
import { ReactComponent as LogoWhite } from "assets/img/LogoWhite.svg";

import { ReactComponent as DiscordLogo } from '../../assets/img/discord.svg'
import { ReactComponent as TwitterLogo } from '../../assets/img/twitter.svg'
import { ReactComponent as MediumLogo } from '../../assets/img/medium.svg'

import './index.less'

const { Panel } = Collapse;
const year = new Date().getFullYear();

export default function Home() {
    const [horizontalArrowActive, setHorizontalArrowActive] = useState(false);
    const [verticalArrowActive, setVerticalArrowActive] = useState(false);
    const [elementHeight, setElementHeight] = useState(568);

    const animation = (id: string) => {
        const revealElement = document.getElementById(id);
        if(revealElement){
            const windowHeight = window.innerHeight;
            const elementTop = revealElement.getBoundingClientRect().top;
        
            if (elementTop < windowHeight/2) {
                id === 'horizontalArrows' ? setHorizontalArrowActive(true) : setVerticalArrowActive(true)
            } else {
                id === 'horizontalArrows' ? setHorizontalArrowActive(false) : setVerticalArrowActive(false)
            }
        } 
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {animation('horizontalArrows')});
        window.addEventListener("scroll", () => {animation('verticalArrows')});
        const revealElement1 = document.getElementById("horizontalArrows");
        const revealElement2 = document.getElementById("verticalArrows");
        if(revealElement1){
            setElementHeight(revealElement1.getBoundingClientRect().height)
        }  
        if(revealElement2){
            setElementHeight(revealElement2.getBoundingClientRect().height)
        }  
        return () => {
            window.removeEventListener("scroll",() => {animation('horizontalArrows')});
            window.removeEventListener("scroll",() => {animation('verticalArrows')});
        };
    }, []);

    const handleCollapseChange = (key: string | string[]) => {
        if(key.length >= 1){
            setVerticalArrowActive(true)
        }else {
            setVerticalArrowActive(false)
        }
    };

    return (
        <div className="home">
            <div className="first_content" id="Home">
                <div className="content">
                    <div className='first'>
                        <div>
                            <h1>Web3 Storverse</h1>
                            <p>Store and manage your own content on Web3 via Storverse.</p>
                            <div className='luanchbtn'>
                                <Link className='luanchlink' to="/miningpool"> Launch App now</Link>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className='group_content'>
                <div className='content'>
                    <div className='groups'>
                        <img className='groupBg' src={groupBg} alt="" />
                        <FloatDiv delay={1} down={0}>
                        <div className='group1' id="group1s" >
                            <img src={group1} alt="" />
                            <div className='group1_text'>
                            <h2>Open Data Model</h2>
                            <p>Store and classify your content with high efficient computation.</p>
                            </div>
                          
                        </div>
                        </FloatDiv>
                        <FloatDiv delay={1.5} down={25} zindex={5}>
                       
                        <div className='group2'>
                            <img src={group2} alt="" />
                            <div  className='group2_text'>
                            <h2>Cryptographic Primitive</h2>
                            <p>Ensure your content privacy and ownership through encryption and decryption mechanism.</p>
                            </div>
                            
                        </div>
                        </FloatDiv>
                        <FloatDiv delay={2.5} down={25}>
                        
                        <div className='group3'>
                            <img src={group3} alt="" />
                            <div  className='group3_text'>
                            <h2>Proof of Existence(POE)</h2>
                            <p>Possess and manage your content via POE on de-storage network.</p>
                            </div>
                           
                        </div>
                        </FloatDiv>
                        <FloatDiv delay={3.5} down={25} >
                        
                        <div className='group4'>
                            <img src={group4} alt="" />
                            <div  className='group4_text'>
                            <h2> Decentralized Digital Right Management</h2>
                            <p>Protect copyrighted content by decentralized key distribution system and data provenance technologies.</p>
                            </div>
                           
                        </div>
                        </FloatDiv>
                        <div id='horizontalArrows'>
                            <div className={`left ${horizontalArrowActive ? 'active' : ''}`}>
                                <img src={groupline1} alt="" />
                            </div>
                            <div className={`right ${horizontalArrowActive ? 'active' : ''}`}>
                                <img src={groupline2} alt="" />
                            </div>
                        </div>      
                    </div>
                </div>
            </div>
            <div className='three_content'>
                <div className='content'>
                    <div className='threetop'>
                        <img className='threetop_img' src={threetop} alt="" />
                    </div>
                    <div className='three_data'>
                        <div className='data_details1'>
                            <img src={threeicon1} className='threeicon' alt="" />
                            <h2>Joint Mining</h2>
                            <p>A mining pool to earn FIL by easy staking</p>
                            <Link className='LaunchBtn' to="/miningpool"> Launch App</Link>
                            
                            {/* <p className='LaunchBtn'>Launch App</p> */}
                        </div>
                        <div className='data_details2'>
                            <img src={threeicon2} className='threeicon' alt="" />
                            <h2>Data Store</h2>
                            <p>An easy-to-use store platform based on de-storage blockchain</p>
                            <Link className='LaunchBtn' to="/datastore"> Launch App</Link>
                            
                        </div>
                        <div className='data_details3'>
                            <img src={threeicon3} className='threeicon' alt="" />
                            <h2>Search Engine</h2>
                            <p>A powerful search engine for Web3 content like NFTs</p>
                            <Link className='LaunchBtn' to="">Coming Soon</Link>
                            
                        </div>
                    </div>
                    <div className='threebtm'>
                        <img className='threebtm_img' src={threebtm} alt="" />

                    </div>
                </div>
            </div>
            <div className='storage_content'>
                <div className='autocenter'>
                    <h1>Storage DAO</h1>
                    <p>A completely decentralized storage autonomous organization that governs SAO Network.</p>
                    <div className='more_details'>
                    
                        <div className='morebtn'>
                        MORE DETAILS
                            {/* <Link className='LaunchBtn' to="/miningpool">MORE DETAILS</Link> */}
                        
                        </div>

                    </div>
                </div>

            </div>
            <div className='btm_all'>
            <div className='greenlong_bg'>

            </div>
           
            <div className='road_map'  id="Roadmap">
           
                <div className='greenline'>
                    <div className='greenline1'>

                    </div>
                    
                    <div className='greenline2'>

                    </div>
                </div>
              
                <div className='content'>

                    <img src={roadmapbg} alt="" />
                    <h2 className='q1'>2023 Q1</h2>
                     <ul className='q1_content'>
                       <li>Launch a powerful Search Engine App</li>
                       <li> Launch a Storage DAO</li>
                    </ul>
                   
                    <h2 className='q2'>Q2</h2>
                    <ul className='q2_content'>
                       <li>Launch Joint Mining</li>
                       <li>Open API Key for enterprise clients</li>
                       <li>Release SAO Data Store App</li>
                    </ul> 
                    <h2 className='q3'>Q3</h2>
                    <ul className='q3_content'>
                       <li>Provide optimal customized storage solutions</li>
                       <li>NFTization of the storage miner operated by SAO</li>
                    </ul> 
                    
                    <h2 className='q4'>Q4</h2>
                    <ul className='q4_content'>
                       <li>Upgrade SAO Network to enable FVM</li>
                    </ul> 
                  
                    <h1 className='road_maptitle'> Roadmap</h1>
                </div>
            </div>

            {/* <div className="third_content" id="DataStore">
                <div className="content">
                    <div className='third'>
                        <div>
                            <h1>Data Store</h1>
                            <p>
                                SAO Data Store (“SDS”) provides an easy-to-use store pattern of any data that is useful or meaningful for our users on Filecoin Network, including files, pictures, videos, music, digital artworks, and data backup, etc. SDS integrates the smart contract based on multiple blockchains with oracle technology. Unlike the current existing internet which relies on server-client relationships, the data stored and managed by means of SAO Network is being verified, well processed and safely kept by the protocol. When users (data providers) upload files by the means of “SDS”, they can choose whether the data is for public use or for personal use only.
                            </p>
                        </div>
                        <div>
                            <img src={Image2} alt="" />
                        </div>
                    </div>
                    <h2>Mission</h2>
                    <ul>
                        <li>Provide users with an easy way to upload and store files on to the distributed peer-to-peer networks.</li>
                        <li>Release a feature of store-to-earn to reward the data providers.</li>
                        <li>Enable users to mint NFTs with metadata of their stored files.</li>
                        <li>Provide Web3 applications with some publicly available data they need.</li>
                        <li>Create a standard accounting system for data billing and pricing to benefit the data providers.</li>
                    </ul>
                </div>
            </div> */}
            {/* <div className="fourth_content" id="JointMining">
                <div className="content">
                    <div><img src={Image3} alt="" /></div>
                    <div>
                        <h1>Joint Mining</h1>
                        <p>
                            SAO Network is set to upgrade the concept of cloud mining to “joint mining”. With a strong technical know-how in the development and operations of Filecoin storage mining, the core team of SAO Network has developed a “Joint Mining” product. It allows users to join the mining business to earn FIL (a cryptocurrency that powers the Filecoin network) by staking FIL and obtaining a certain amount of storage power shares. We call it “stake-to-earn”.
                        <br />
                            <br />
                            The core team will take charge of the miners covering the procurement and configuration of equipment, the development and operations of software.
                        </p>
                        <Button type="default"><Link to="/miningpool">JOIN MINING</Link></Button>
                    </div>
                </div>
            </div> */}
            {/* <div className="fifth_content" id="SearchEngine">
                <div className="content">
                    <div>
                        <h1>Search Engine</h1>
                        <p>SAO Product Descriptions</p>
                        <hr />
                        <h2>A Powerful Search Engine</h2>
                        <p>
                            SAO Network is to provide a powerful search engine for useful data like NFTs, historical documents, Meteorological data, online libraries, etc., which comes from the publicly available data of “Sao data store” and  research documents.
                        </p>
                    </div>
                    <div>
                        <img src={Image4} alt="" />
                    </div>
                </div>
            </div> */}
            {/* <div className="sixth_content" id="StorageDAO">
                <div className="content">
                    <div>
                        <img src={Image5} alt="" />
                    </div>
                    <div>
                        <h1>Storage DAO</h1>
                        <p>
                            The Storage DAO of SAO Network will be a completely decentralized storage autonomous organization that governs SAO Network. It will consist of core team, data providers, validators, mining shareholders, community members and other stakeholders that contribute to the security and stability of SAO Network. The Storage DAO will operate in a truly democratically autonomous way to determine how the Network functions and how prospective incentives are distributed, so as to serve the interests of every single user and stakeholder.
                        </p>
                    </div>
                </div>
            </div> */}
            {/* <div className='roadmap' id="Roadmap">
                <div className='content'>
                    <h1>Roadmap</h1>
                    <div className='items'>
                        <div>
                            <h3>2022 Q2</h3>
                            <ul>
                                <li>Launch Joint Mining App as the first step of storage infrastructure of SAO Network.</li>
                                <li>Open the API key for enterprise clients onboarding data to Filecoin.</li>
                                <li>Release SAO Data Store App for any clients onboarding data to Filecoin.</li>
                            </ul>
                        </div>
                        <div>
                            <h3>2022 Q3</h3>
                            <ul>
                                <li>Provide optimal storage solutions according to client requirements in Data Store.</li>
                                <li>Launch data marketplace for storage providers and clients in Data Store.</li>
                            </ul>
                        </div>
                        <div>
                            <h3>2022 Q4</h3>
                            <ul>
                                <li>Upgrade SAO network to enable the FVM (Filecoin Virtual Machine)  to automatically distribute mining revenue in Joint Mining App.</li>
                                <li>Tokenization of the storage power certification within Joint Mining app.</li>
                            </ul>
                        </div>
                        <div>
                            <h3>2023 Q1</h3>
                            <ul>
                                <li>Launch a powerful Search Engine App for useful content retrieving and indexing, eg. NFT data, online libraries, and other public data.</li>
                                <li>- Launch a Storage DAO that governs SAO Network and determines more applications development planning in Web3 area.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='background'>
                        <img src={Circle4} alt="" />
                        <img src={Circle4} alt="" />
                    </div>
                </div>
            </div> */}
            <div className='seventh_content'>
                <div className='content'>
                    <h1>FAQs</h1>
                    <Collapse onChange={handleCollapseChange} expandIcon={({ isActive }) => { return isActive ? <MinusOutlined style={{ fontSize: '20px', color: '#40BA5B' }} /> : <PlusOutlined style={{ fontSize: '20px',color: '#40BA5B' }} /> }} expandIconPosition="right">
                        <Panel header="Q1 What is SAO Network?" key="1">
                            
                            <p>SAO Network is a decentralized storage infrastructure for Web3. It provides a decentralized storage protocol based on IPFS & Filecoin to increase the adoption of Web3 content storage and promote its ecosystem applications.</p>
                        </Panel>
                        <Panel header="Q2 What is Web3?" key="2">
                            <p>Web3 is a concept that is used to describe the evolution of the internet. The Web3 internet is built on a fundamentally different infrastructure, utilizing blockchains and distributed peer-to-peer networks, compared with the current existing internet which relies on server-client relationships.</p>
                        </Panel>
                        <Panel header="Q3 How to join in mining?" key="3">
                     
                            <p>Firstly, log in to the SAO joint mining    <Link target = "_blank" to="https://sao.network/#/miningpool"> web page </Link>
                         by connecting your wallet, and bind a Filecoin address belonging to you. For next steps, see more details   <Link target = "_blank" to="https://sao.network/#/miningpool"> here </Link>.</p>
                        </Panel>
                        <Panel header="Q4 What kind of data can be stored on SAO Network?" key="4">
                            <p>Any data that is useful or meaningful to data owners can be stored on SAO, including files, pictures, videos, music, digital artworks, data backup, etc.</p>
                        </Panel>
                        <Panel header="Q5 What is SAO Pass?" key="5">
                            <p>There are 1,000 SAO Pass NFT that correspond to appointed physical miners operated by SAO Network which means NFT holders can stake their SAO Pass to share mining rewards. Each NFT has innate and immutable properties which are predefined in smart contracts on blockchain. The higher the rarity of your Pass, the more its mining rewards.</p>
                        </Panel>
                    </Collapse>
                    <div id="verticalArrows" className={`${verticalArrowActive ? 'active' : ''}`}>
                        <img src={updown} alt="" />
                        <img src={downup} alt="" />
                    </div>
                </div>
              
            </div>
            </div>
            <div className="footer">
                <div className="content">
                    <div>
                        <LogoWhite className='logo' />
                        
                    </div>
                    <div className='footer_icon'><ul>
                            <li><a href="https://discord.gg/q58XsnQqQF" target="_blank"><DiscordLogo /></a></li>
                            <li><a href="https://twitter.com/SAONetwork" target="_blank"><TwitterLogo /></a></li>
                            {/* <li><a href="" target="_blank"><FacebookLogo/></a></li>
                            <li><a href="" target="_blank"><InstergramLogo/></a></li> */}
                            <li><a href="https://medium.com/@saonetwork" target="_blank"><MediumLogo /></a></li>
                        </ul> <a href="mailto:contact@sao.network">contact@sao.network</a></div>
                </div>
            </div>
            <div className='copyright'>
                ©Copyright {year} Blockinsight PET. LTD.  All Rights Reserved.
                <ul>
                    <li><a href='https://docs.sao.network/agreements/terms-of-service' target="_blank">Terms of Service</a></li>
                    <li><a href='https://docs.sao.network/agreements/privacy-policy' target="_blank">Privacy Policy</a></li>
                    <li><a href="mailto:contact@sao.network">Support</a></li>
                </ul>
            </div>
        </div>
    )
}
