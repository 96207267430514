import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { MenuOutlined, DownOutlined } from '@ant-design/icons';
import { Layout, Select, Button, Dropdown, Menu, notification } from 'antd';
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useFetchFilAddress, useFetchDepositResult, useDepositResult } from 'state/account/hooks'
import Web3Status from '../Web3Status'
import { NETWORK_CHAIN_ID } from 'constants/index'
import { networkListener, setupEthMainnet, setupEthTestnet } from 'utils/bscUtil'
import Logo from "assets/img/Logo.svg";
import LogoWhite from "assets/img/LogoWhite.svg";
import NewIcon from "assets/img/new.svg";
import './index.less'

 
const { Option } = Select;
const { Header: HeaderElement } = Layout;
const HeaderContent = styled.div`
    margin: 0 auto;
    padding: 0 35px;
    @media (max-width: 767px) {
        padding: 0 30px;
    }
`
let interval1: any;
const Header = () => {
  // const { chainId } = useActiveWeb3React()
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  let location = useLocation();
  const { account, library, chainId } = useActiveWeb3React()
  const fetchFilAddress = useFetchFilAddress();
  const [startTime, setStartTime] = useState<string>('');
  const fetchDepositResult = useFetchDepositResult();
  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };
  const depositID = useDepositResult();
  const history = useHistory();
  const Nav1 = location.pathname === "/" || location.pathname === "/nft";
  const Nav2 = location.pathname !== "/" && location.pathname !== "/nft";

  const scrollTo = (id: any) => {
    const ele = document.getElementById(id);
    if(!ele){
        return
    }
    ele.scrollIntoView({
        behavior: "smooth",
        block: "start",
    });
  };

  const scrollHeader = () => {
    if (!document.querySelector('.ant-layout-header')) {
        return;
    }

    var scrollTop = document.documentElement.scrollTop;
    if (scrollTop >= 60) {
      setIsScroll(true)
    } else {
      setIsScroll(false)
    }
  }

  useEffect(() => {
    if(account && library && location.pathname !== '/'){
      fetchFilAddress();
      const time = localStorage.getItem('depositModal');
      if(time){
        setStartTime(time)
      }
    }
  }, [account, library, location.pathname])

  useEffect(() => {
    if(startTime){
        interval1 = setInterval(() => {
          fetchDepositResult(startTime);
        }, 10000);
    }
  },[startTime])

  const openNotification = () => {
    notification.open({
      message: 'Deposit successful.',
      description:
        'View details via your [Account] - [Mining Dashboard]',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  useEffect(() => {
    if(depositID){
      localStorage.removeItem('depositModal')
      openNotification()
      if(interval1){
        clearInterval(interval1);
      }
    }
  }, [depositID])

  useEffect(() => {
    networkListener();
    window.addEventListener('scroll', scrollHeader, false);

    return () => {
      window.removeEventListener('scroll', scrollHeader, false);
    }
  }, [])

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    if(location.pathname !== '/'){
      setIsScroll(true)
      if(chainId){
        document.addEventListener("visibilitychange", function() {
            if (document.visibilityState === 'visible') {
              if(chainId !== NETWORK_CHAIN_ID){
                NETWORK_CHAIN_ID === 1 ? setupEthMainnet(chainId) : setupEthTestnet()
              }
            }
        });
        if (document.visibilityState === 'visible') {
          if(chainId !== NETWORK_CHAIN_ID){
            NETWORK_CHAIN_ID === 1 ? setupEthMainnet(chainId) : setupEthTestnet()
          }
        }
      }
    }
    if(location.pathname && isNavVisible){
      setNavVisibility(!isNavVisible);
    }
  }, [location.pathname])

  const handleMediaQueryChange = (mediaQuery: any) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const isShowBlackBackground = () => {
    if (location.pathname === "/") return false
    return true
  }

  const productsMenu = (
    <Menu>
        <Menu.Item onClick={() => {scrollTo('DataStore')}}>
          Data Store
        </Menu.Item>
        <Menu.Item onClick={() => {scrollTo('JointMining')}}>
          Joint Mining
        </Menu.Item>
        <Menu.Item onClick={() => {scrollTo('SearchEngine')}}>
          Search Engine
        </Menu.Item>
        <Menu.Item onClick={() => {scrollTo('StorageDAO')}}>
          Storage DAO
        </Menu.Item>
    </Menu>
)
if (location.pathname.toLowerCase().indexOf("discord") > 0) {
    return (<div />)
}

  return (
    <HeaderElement className={`${isScroll || isShowBlackBackground() ? 'black' : 'light'}`}>
      <HeaderContent className="header">
        <Link to="/">
          <img
            src={LogoWhite}
            className="Logo"
            alt="logo"
            onClick={() => history.push("/")}
          />
        </Link>
        {!isSmallScreen && 
          <CSSTransition
            in={!isSmallScreen || isNavVisible}
            timeout={350}
            classNames="NavAnimation"
            unmountOnExit
          >
            <>
              <nav className="Nav left">
                {Nav1 && 
                  <>
                    <Link to="/" onClick={() => {scrollTo('Home')}} className={location.pathname === '/' ? 'active': ''}>Home</Link>
                    {/* <Dropdown overlay={productsMenu} trigger={['click']}>
                      <a>Products <DownOutlined /></a>
                    </Dropdown> */}
                    <a onClick={() => {scrollTo('Roadmap')}}>Roadmap</a>
                    <a target="_blank" href="https://docs.sao.network">Docs</a>
                    <a target="_blank" href="https://medium.com/@saonetwork">Blog</a>
                    {/* <Link to="/nft" className={location.pathname === '/nft' ? 'active': ''}>NFT <img src={NewIcon} alt="" /></Link> */}
                  </>
                }
                {Nav2 && <Link to="/miningpool">Joint Mining</Link>}
                {Nav2 && <Link to="/datastore">DataStore</Link>}
                {/* {Nav2 && <Link to="/nft/dashboard">NFT Mining</Link>} */}
              </nav>
              <nav className="Nav right">
                { Nav1 && <p className='Launchbtn'><Link to="/miningpool" style={{fontWeight: 600}}>Launch app now</Link></p> }
                { Nav2 && <Web3Status className={`${isScroll || isShowBlackBackground() ? 'black' : 'light'}`}/> }
                {/* <a href="https://twitter.com/SAONetwork" target="_blank"><TwitterLogo/></a> */}
                {/* <a href="https://github.com/SaoNetwork" target="_blank"><GithubLogo fill={(isScroll || isShowBlackBackground()) && !isSmallScreen ? "#fff" : "#000"}/></a> */}
              </nav>
            </>
          </CSSTransition>
        }
        {Nav2 && <button className="wallet-icon">
          <Web3Status className="black"/>
        </button>}
        <button onClick={toggleNav} className="Burger">
          <MenuOutlined style={{color:'#fff'}}/>
        </button>
      </HeaderContent>
      {isSmallScreen && 
          <CSSTransition
            in={!isSmallScreen || isNavVisible}
            timeout={350}
            classNames="NavAnimation"
            unmountOnExit
          >
            <nav className="Nav left">
              {Nav1 && 
                <>
                  <Link to="/" onClick={() => {scrollTo('Home')}} className={location.pathname === '/' ? 'active': ''}>Home</Link>
                  {/* <Dropdown overlay={productsMenu} trigger={['click']}>
                    <a>Products <DownOutlined /></a>
                  </Dropdown> */}
                   <a onClick={() => {scrollTo('Roadmap')}}>Roadmap</a>
                  <a target="_blank" href="https://medium.com/@saonetwork">Blog</a>
                  
                  <a target="_blank" href="https://docs.sao.network">Docs</a>
                  {/* <Link to="/nft" className={location.pathname === '/nft' ? 'active': ''}>NFT <img src={NewIcon} alt="" /></Link> */}
                </>
              }
              {Nav2 && <Link to="/miningpool">Joint Mining</Link>}
              {Nav2 && <Link to="/datastore">DataStore</Link>}
              {Nav1 &&  <div className='luanchbtn'>
                  <Link className='luanchlink' to="/miningpool"> Launch App now</Link>
              </div>}
            </nav>
          </CSSTransition>
        }
    </HeaderElement>
  );
};
export default Header;
