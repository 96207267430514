import { useState, useEffect } from "react";
import styled from "styled-components";
import { RouteComponentProps, useLocation, Link } from 'react-router-dom'
import { post } from '../../utils/request'
import { ModelsNftItem } from '../../interface'

import { ReactComponent as CloseSvg } from '../../assets/img/x.svg'
import { ReactComponent as ListTypeSvg } from '../../assets/img/list_type.svg'
import { ReactComponent as ModuleTypeSvg } from '../../assets/img/module_type.svg'
import { ReactComponent as CopyIcon } from '../../assets/img/copyIcon.svg'

import testImg1 from '../../assets/img/test_img1.png'
import testImg2 from '../../assets/img/test_img2.png'
import ethLogo from '../../assets/img/ethereum-logo.png'
import opensea from '../../assets/img/opensea.png'


interface searchData {
  imgUrl: string;
  productName: string;
  productNum: string;
  projectName: string;
  projectUrl: string;
  platformName: string;
  platformUrl: string
  chain: string
}

const SearchList = (props: RouteComponentProps<{ keywords: string }>) => {
  const {
    match: {
      params: { keywords }
    }
  } = props
  const [searchData, setSearchData] = useState([])
  const [totalSearchNum, setTotalSearchNum] = useState(0)
  useEffect(() => {
    post("https://staging-api.sao.network/v1/nft/search", {
      "key": keywords,
      "pageNo": 1,
      "pageSize": 100
    }).then(data => {
      if (data) {
        setTotalSearchNum(data.data.total)
        setSearchData(data.data.data)
      }
      
    })
  }, [])
  const [isActiveModuleType, setIsActiveModuleType] = useState(true)
  const handleSwitchCardButton = (isModuleType: boolean) => {
    if (isModuleType === isActiveModuleType) return
    setIsActiveModuleType(!isActiveModuleType)
  }
  const getShortAddress = (address: string | undefined) => {
    return `${address?.slice(0, 5)} ... ${address?.slice(address.length - 4, address.length)}`
  }
  return (
    <SearchWraper>
      <SearchHeader>
        <SearchHeaderLeft>
          {totalSearchNum} results
          {/* <SearchTag>CryptoKitties<CloseSvg /></SearchTag> */}
        </SearchHeaderLeft>
        <SwitchCardType>
          <SwitchCardButton 
           onClick={() => handleSwitchCardButton(true)}
           isActive={isActiveModuleType}
          >
            <ModuleTypeSvg />
          </SwitchCardButton>
          <SwitchCardButton
            onClick={() => handleSwitchCardButton(false)}
            isActive={!isActiveModuleType}
          >
            <ListTypeSvg />
          </SwitchCardButton>
        </SwitchCardType>
      </SearchHeader>
      <SearchContent>
        {isActiveModuleType ? (
            <ModuleTypeContent>
              {searchData.map((data: ModelsNftItem) => {
                return (
                    <Link to={`/detail/${data.Id}`} >
                      <ModuleItem>
                        {data.Url ? (
                          <ModuleImg src={data.Url}  />
                        ) : (
                          <ModuleImgDefault />
                        )}
                        <ModuleBottom>
                          <NFTNameContent>
                            <NFTName>
                              {data.Name}
                            </NFTName>
                            <img src={ethLogo}  />
                          </NFTNameContent>
                          <LinkWraper>
                            {/* <LinkUrl href={data?.Platform?.Name}>{data?.Platform?.Name || "unknow"}</LinkUrl> */}
                            <LinkUrl href={`https://etherscan.io/address/${data?.Collection?.ContractAddress}`}>{data?.Collection?.Name || "unknow"}</LinkUrl>
                          </LinkWraper>
                        </ModuleBottom>
                      </ModuleItem>
                    </Link>
                )
              })}
            </ModuleTypeContent>
        ) : (
          <ListTypeContent>
            <ListWraper>
              {searchData.map((data: ModelsNftItem) => {
                return (
                  <ListItem>
                    <LIstImg src={data.Url} />
                    <ListContent>
                      <NFTName>
                        {data.Name} 
                      </NFTName>
                      <NFTDescription>
                        {data.Description}
                      </NFTDescription>
                      <ChainInfo>
                        <ChainName>
                          <img src={ethLogo} alt="eth" />
                          Ethereum
                        </ChainName>
                        <Address>
                          {getShortAddress(data?.ContractAddress)}
                          <CopyIcon />
                        </Address>
                      </ChainInfo>
                      <LinkWraper>
                          {/* <LinkUrl href={data?.Platform?.Name}>{data?.Platform?.Name}</LinkUrl> */}
                          {/* <LinkUrl href={data?.Collection?.Name}>{data?.Collection?.Name}</LinkUrl> */}
                          <LinkUrl href={`https://etherscan.io/address/${data?.Collection?.ContractAddress}`}>{data?.Collection?.Name || "unknow"}</LinkUrl>
                      </LinkWraper>
                    </ListContent>
                  </ListItem>
                )
              })}
            </ListWraper>
            {searchData.length ? (
              <PlatformRecmond>
                <PlatformTop>
                  <PlatformImg>
                    <img src={opensea} alt="opensea" />
                  </PlatformImg>
                  <RightContent>
                    <PlatformName>
                      OpenSea
                    </PlatformName>
                    <PlatformDescription>
                      The NFT Social Platform
                    </PlatformDescription>
                  </RightContent>
                </PlatformTop>
                <PlatformBottom>
                  <BottomImgWraper>
                    <img src={testImg2} alt="" />
                    <img src={testImg2} alt="" />
                    <img src={testImg2} alt="" />
                  </BottomImgWraper>
                  <BottomText>
                    <a>Problem with description? Contact us</a>
                  </BottomText>
                </PlatformBottom>
              </PlatformRecmond>
            ) : ""}

          </ListTypeContent>
        )}
      </SearchContent>
    </SearchWraper>
  )
}

const SearchWraper = styled.div`
  padding-top: 70px;
`

const SearchHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid #DEDEDE ;
  padding-left: 57px;
  padding-right: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SearchHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

const SearchTag = styled.div`
  margin-left: 15px;
  background: #DEDEDE;
  border-radius: 50px;
  display: inline-block;
  padding: 5px 14px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 17px;
    width: 12px;
    cursor: pointer;
  }
`
const SwitchCardType = styled.div`
  width: 100px;
  height: 40px;
  display: flex;
  background-color: #F4F4F4;
  border-radius: 6px;
  overflow: hidden;
`
const SwitchCardButton = styled.div<{ isActive?: boolean }>`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color:  ${props => props.isActive ? "#DEDEDE" : "#F4F4F4"};
  svg {
    path, rect {
      fill: ${props => props.isActive ? "#BABABA" : "#000000"}
    }
  }
`

const SearchContent = styled.div`
  margin-top: 14px;
  text-align: center;
`

const ModuleTypeContent = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(auto-fill, 320px);
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const ModuleItem = styled.div`
  max-width: 320px;
  background: #F4F4F4;
  justify-self: center;
`
const ModuleImgDefault = styled.div`
  width: 320px;
  height: 320px;
`
const ModuleImg = styled.img`
  width: 320px;
  height: 320px;
`


const ModuleBottom = styled.div`
  padding: 14px 20px;
  text-align: left;
`

const NFTNameContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  img {
    width: 20px;
    height: 20px;
  }
`

const NFTName = styled.span`
  font-size: 16px;
  font-weight: bold;
`

const LinkWraper = styled.div`
  display: inline-block;
  position: relative;
`

const LinkUrl = styled.a`
  color: #3882D9;
  font-size: 14px;
  text-decoration: underline;
  position: relative;
  &:first-child {
    margin-right: 26px;
    &:after {
    content: "";
    border-right: 1px solid #9A9A9A;
    width: 0;
    height: 16px;
    position: absolute;
    top: 50%;
    right: -13px;
    transform: translate(0, -50%);
  }
  }
`

const ListTypeContent = styled.div`
  display: inline-grid;
  grid-template-columns: 1.85fr 400px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;  
`

const ListWraper = styled.div`

`

const PlatformRecmond = styled.div`
  height: 366px;
  border: 1px solid #BBBBBB;
  border-radius: 10px;
`

const ListItem = styled.div`
  width: 740px;
  border: 1px solid #BBBBBB;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 9px;
  display: flex;
  text-align: left;
`

const LIstImg = styled.img`
  width: 180px;
  height: 180px;
`

const ListContent = styled.div`
  margin-left: 40px;
  ${NFTName} {
    font-size: 24px;
  }
`

const NFTDescription = styled.div`
  margin-top: 9px;
  margin-bottom: 20px;
  font-size: 14px;
  max-width: 405px;
`

const ChainInfo = styled.div`
  background: #F4F4F4;
  border-radius: 50px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 350px;
  margin-bottom: 12px;
`

const ChainName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 16px;
  position: relative;
  &:after {
    content: "";
    width: 0;
    height: 18px;
    border-left: 1px solid #DEDEDE;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(0, -50%);
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
`

const Address = styled.div`
  display: flex;
  align-items: center;
  padding-left: 18px;
  svg {
    margin-left: 27px;
    cursor: pointer;
  }
`

const PlatformTop = styled.div`
  border-bottom: 1px solid #BBBBBB;
  padding: 30px 27px;
  display: flex;
  justify-content: space-between;
`

const PlatformBottom = styled.div`
  padding: 13px 20px 7px 18px;
`

const PlatformImg = styled.div`

`

const PlatformName = styled.div`
  font-size: 24px;
  margin-bottom: 11px;
  font-weight: bold;
`

const PlatformDescription = styled.div`
  font-size: 16px;
`

const RightContent = styled.div`
  display: flex;
  align-items: ceonter;
  justify-content: center;
  flex-direction: column;
  text-align: left;
`

const BottomImgWraper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 114px;
    height: 114px;
    border-radius: 4px;
  }
`

const BottomText = styled.div`
  text-align: right;
`
export default SearchList 